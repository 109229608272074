.modal-body {
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 400px;
}

@media screen and (max-width: 425px) {
    .modal-body {
        width: calc(100vw - 30px);
    }
}

.modal-body > * {
    flex-grow: 1;
    position: relative;
}

.modal-header {
    color: #8f8f8f;

    display: flex;
    justify-content: flex-end;
}

span {
    display: inline;
}

.close-modal-button {
    position: absolute;
    right: 5px;
    z-index: 60;
    cursor: pointer;
    font-size: 60px;
    color: #484848;

    border: none;
    background-color: transparent;
}

.modal-content {
    background-color: #8f8f8f;
    border-radius: 20px;

    padding: 35px 65px;

    display: flex;
    gap: 10px;
    flex-direction: column;
}

.text-main-color {
    color: #484848;
}

.text-secondary-color {
    color: #aed145;
}

.modal-content-title {
    font-size: 25px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-content-subtitle {
    font-size: 18px;
}

.modal-content-subtitle span {
    display: inline-block;
    font-size: 16px;
    margin-top: 40px;
}